import Swiper from "swiper/bundle";

export function idxFunc() {
  const swiper = new Swiper('.swiper', {
    speed: 1000,
    loop: true,
    autoplay: {
      delay: 10000,
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    },
    slidesPerView: 1,
    spaceBetween: 0,  
    breakpoints: {
      768: {
        slidesPerView: 'auto',
        spaceBetween: 24,  
      }
    }
  });

  $('.menu-dl__dt').on('click', function() {
    $(this).parent().toggleClass('open');
    $(this).next('.menu-dl__dd').slideToggle(800, 'swing');
  });
}
